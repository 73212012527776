import React from 'react';
import {css} from '@emotion/core';
import loadable, {LoadableComponent} from '@loadable/component';

import {chromeDefaultSearchExtensionLink} from '../../constants';

import MapquestLogoSmall from '../../assets/svg/mapquest-logo-black-small.svg';
import LoadingCircles from '../../assets/svg/loading-circles-blue.svg';

// @ts-expect-error
import ModalOverlay from '../../components/ModalOverlay';
// @ts-expect-error
import Layout from '../../components/Layout.tsx';
import SEO from '../../../../common/components/SEO';
import MarketingLayout from '../../components/MarketingLayout';
import {useCTA} from '../../hooks/useCTA';
import {useExperiment} from '../../../../common/experiments/experimentContext';
import {ExperimentProps} from '../../components/templates/type';

const experiments: Record<string, LoadableComponent<ExperimentProps>> = {
    default: loadable<ExperimentProps>(() =>
        import(
            /* webpackChunkName: "DirectionsSearchDefault" */ `../../components/experiments/directions-search/default`
        )
    )
};

const DirectionsSearch = () => {
    const {experimentId} = useExperiment();
    const ExperimentComponent = experimentId in experiments ? experiments[experimentId] : experiments.default;

    const {isModalOverlayVisible, onCtaClick} = useCTA({
        webStoreLink: chromeDefaultSearchExtensionLink,
        isPrimary: true
    });

    return (
        <MarketingLayout>
            <Layout>
                <SEO
                    title="Install Mapquest Extension"
                    description="Quickly search step-by-step directions to your destination with MapQuest Directions Search!"
                />
                <ExperimentComponent onCtaClick={onCtaClick} />
                <ModalOverlay isVisible={isModalOverlayVisible} maxWidth="300">
                    <MapquestLogoSmall />
                    <h2
                        css={css`
                            margin: 0.75rem 0;
                            font-weight: 400;
                            font-size: 16px;
                        `}
                    >
                        Click &lsquo;Add to Chrome&lsquo; button to continue
                    </h2>
                    <LoadingCircles
                        css={css`
                            width: 100px;
                            height: 100px;
                        `}
                    />
                </ModalOverlay>
            </Layout>
        </MarketingLayout>
    );
};

export default DirectionsSearch;
